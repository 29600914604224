<template>
  <div>
    <toast />
    <v-app dark>
      <bg></bg>
      <template v-if="logged">
        <template v-if="loaded">
          <app_bar @drawed="userDrawer = true"></app_bar>
          <user_drawer v-model="userDrawer"></user_drawer>
          <v-main>
            <v-scroll-x-transition>
              <router-view />
            </v-scroll-x-transition>
          </v-main>
        </template>
      </template>
      <template v-else>
        <login_bar />
        <v-main>
          <login />
        </v-main>
      </template>
    </v-app>
  </div>
</template>
<script>
import storage from "@/storage";
 

if (process.env.NODE_ENV !== "production") {
  require("roboto-fontface/css/roboto/roboto-fontface.css");
}
import { mapGetters } from "vuex";
import { lauderLarken } from "@/plugins/axios";
export default {
  methods: {
    async validateToken() {
      try {
        this.loaded = false;
        this.$store.commit("loaderAppear", true);
        this.$store.commit("loaderText", "Collecting");
        if (storage.getToken() && window.navigator.onLine) {
          let result = await lauderLarken.validateToken();
          let { message, token } = result.data;
          if (message) {
            await this.$store.dispatch("lauderlogout");
            return;
          }
          if (token) {
            storage.setToken(token);
          }
        }
      } catch (e) {
        if (e.message !== "Network Error") {
          await this.$store.dispatch("lauderlogout");
        }
        // console.log(e);
      }
      this.loaded = true;
      this.$store.commit("loaderAppear", false);
    }
  },
  components: {
    Toast: () => import("@/components/toast"),
    Bg: () => import("@/components/bg"),
    Login: () => import("@/views/login"),
    login_bar: () => import("@/components/main/login_bar"),
    user_drawer: () => import("@/components/main/user_drawer"),
    //app_footer: () => import("@/components/main/app_footer"),
    app_bar: () => import("@/components/main/app_bar")
  },
  name: "app",
  data() {
    return {
      transitionName: "drain",
      userDrawer: false,
      loaded: false
    };
  },
  watch: {
    $route(to, from) {
      const toDepth = to.path.split("/").length;
      const fromDepth = from.path.split("/").length;
      if (toDepth < fromDepth) {
        this.transitionName = "drain";
      } else {
        this.transitionName = "slither";
      }
    },
    loggedIn: {
      immediate: true,
      handler(val) {
        if (val) {
          this.validateToken();
        }
      }
    }
  },
  computed: {
    logged() {
      return (
        this.loggedIn && !this.$route.matched.some(r => r.meta.onlyWhenLogout)
      );
    },
    ...mapGetters({
      loggedIn: "laudergetLoginState",
      c: "customToken"
    })
  }
};
</script>
<style lang="scss">
//@import "../../node_modules/";
//@import "../../node_modules/";
.slither-enter-active,
.slither-leave-active {
  transition: transform 0.5s;
}

.slither-enter,
.slither-leave-to {
  transform: translateX(-100%);
}

.slither-enter-to,
.slither-leave {
  transform: translateX(0);
}

.drain-enter-active,
.drain-leave-active {
  transition: transform 0.5s;
}

.drain-enter,
.drain-leave-to {
  transform: translateY(100%);
}

.drain-enter-to,
.drain-leave {
  transform: translateY(0);
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: 0.5s;
}
body.h {
  height: 1000000px;
}
.v-list-item__subtitle {
  white-space: normal !important;
}
</style>
